import React from 'react'
import { CFView, Space, Text } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const UserDetailsView = ({
  email,
  name,
  edit,
  NameInputElement,
  PhoneNumberInputElement,
  CancelEditButtonElement,
  SaveNameButtonElement,
  SavePhoneNumberButtonElement,
  EditNameButtonElement,
  EditPhoneNumberButtonElement,
  PaymentRowElement,
}) => (
  <div className={styles.container}>
    <Text heading h2 red bold>
      Hello {name ? name : email}!
    </Text>
    <Space h2 />
    <div className={styles.row}>
      <Text heading bold h3 white>
        Email
      </Text>
      <CFView w="22rem">
        <Text content h3 white>
          {email}
        </Text>
      </CFView>
    </div>
    <Space h2 />
    <div className={styles.row}>
      <Text heading bold h3 white>
        Name
      </Text>
      <div className={styles.formRow}>
        {NameInputElement}
        <Space w2 />
        {edit === 'name' ? (
          <div className={styles.buttonRow}>
            {CancelEditButtonElement}
            <Space w1 />
            {SaveNameButtonElement}
          </div>
        ) : (
          EditNameButtonElement
        )}
      </div>
    </div>
    <Space h2 />
    <div className={styles.row}>
      <Text heading bold h3 white>
        Phone Number
      </Text>
      <div className={styles.formRow}>
        {PhoneNumberInputElement}
        <Space w2 />
        {edit === 'phone' ? (
          <div className={styles.buttonRow}>
            {CancelEditButtonElement}
            <Space w1 />
            {SavePhoneNumberButtonElement}
          </div>
        ) : (
          EditPhoneNumberButtonElement
        )}
      </div>
    </div>
    <Space h2 />
    {PaymentRowElement}
  </div>
)

const styles = {
  container: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '2rem',
  }),
  formRow: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '22rem',
    [MEDIA_QUERY.SM]: {
      width: '100%',
    },
  }),
  buttonRow: css({
    display: 'flex',
    alignItems: 'center',
  }),
  row: css({
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    height: '4rem',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      height: 'auto',
    },
  }),
}

export default UserDetailsView
