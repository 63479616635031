import React from 'react'
import { Space, Text } from 'components'
import { css } from 'emotion'
import { MEDIA_QUERY } from 'styles'

const UserDetailsPaymentView = ({ last4, EditCCButtonElement }) => (
  <div className={styles.row}>
    <Text heading bold h3 white>
      Credit Card
    </Text>
    <div className={styles.buttonRow}>
      <Text content h3 white>
        **** **** **** {last4}
      </Text>
      <Space w2 />
      {EditCCButtonElement}
    </div>
  </div>
)

const styles = {
  buttonRow: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '22rem',
    [MEDIA_QUERY.SM]: {
      width: '100%',
    },
  }),
  row: css({
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    height: '4rem',
    [MEDIA_QUERY.SM]: {
      flexDirection: 'column',
      height: 'auto',
      alignItems: 'flex-start',
    },
  }),
}

export default UserDetailsPaymentView
