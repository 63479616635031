// For Googlebot (SEO)
import 'babel-polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import WebApp from './WebApp'
// import StyleGuide from './styles/StyleGuide'
import { unregister } from './registerServiceWorker'
import AOS from 'aos'
import 'aos/dist/aos.css'

AOS.init({
  once: false,
  mirror: true,
})
ReactDOM.render(<WebApp />, document.getElementById('root'))
unregister()
