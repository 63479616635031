import React from 'react'
import { Spinner } from 'components'
import { css } from 'emotion'

const LoadingView = () => (
  <div className={styles.loading}>
    <Spinner />
  </div>
)

const styles = {
  loading: css({
    display: 'flex',
    height: '70vh',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  }),
}

export default LoadingView
