import React from 'react'
import { CFView, Text, Space } from 'components'
import { css } from 'emotion'

const ReceiptView = ({
  orderNumber,
  OrderStatusElement,
  completionTimeFormatted,
  pointsEarned,
  PlaceOrderButtonElement,
}) => (
  <div className={styles.cart}>
    <Text heading h1 red center>{`#${orderNumber}`}</Text>
    <Space h3 />
    <CFView fill row justifyCenter alignCenter>
      {OrderStatusElement}
    </CFView>
    <Space h3 />
    <Text content h4 white center>
      {`The estimated pickup time for your order is ${completionTimeFormatted}. Thank you!`}
    </Text>
    <Space h2 />
    {pointsEarned > 0 && (
      <Text content h4 red center>
        {`Congrats! You earned ${pointsEarned} points!`}
      </Text>
    )}
    <Space height="4rem" />
    <div className={styles.button}>{PlaceOrderButtonElement}</div>
  </div>
)

const styles = {
  cart: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  }),
  button: css({
    alignSelf: 'center',
  }),
}

export default ReceiptView
